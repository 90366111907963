import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthaccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private alt: AlertController
) {

}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      const currentUser = this.auth.currentUserValue;
        if (currentUser) {
            // logged in so return true
            let sec = currentUser.sec;
            let role = route.data.expectedRole;
            console.log(sec +' '+  role);
            if(sec != role){
              this.showA();
              this.router.navigate(['/dashboard']);
              return false;
            }
            console.log(currentUser);
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    
  }
   async showA(){
     let alt = await this.alt.create({
       header:"Access Denied",
       cssClass: 'my-class',
       message: "You are Not Authorized to Access",
       buttons: ['OK']
     });
     alt.present();
   }
  
}
