import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './authgaurd.guard';
import {AuthaccessGuard} from './authaccess.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'inv',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./inv/inv.module').then( m => m.InvPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'addstaff',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./addstaff/addstaff.module').then( m => m.AddstaffPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'adduser',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./adduser/adduser.module').then( m => m.AdduserPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'addpro',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./addpro/addpro.module').then( m => m.AddproPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'addcat',
    loadChildren: () => import('./addcat/addcat.module').then( m => m.AddcatPageModule)
  },
  {
    path: 'products',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'users',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'settings',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'reciepts',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./reciepts/reciepts.module').then( m => m.RecieptsPageModule)
  },
  {
    path: 'stock',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./stock/stock.module').then( m => m.StockPageModule)
  },
  {
    path: 'pay',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pay/pay.module').then( m => m.PayPageModule)
  },
  {
    path: 'eodreport',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./eodreport/eodreport.module').then( m => m.EodreportPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'edpro',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./edpro/edpro.module').then( m => m.EdproPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'lookup',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./lookup/lookup.module').then( m => m.LookupPageModule)
  },
  {
    path: 'uorder',
    loadChildren: () => import('./uorder/uorder.module').then( m => m.UorderPageModule)
  },
  {
    path: 'pos1',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pos1/pos1.module').then( m => m.Pos1PageModule)
  },
  {
    path: 'pos2',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./pos2/pos2.module').then( m => m.Pos2PageModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('./accounts/accounts.module').then( m => m.AccountsPageModule)
  },
  {
    path: 'accreport',
    loadChildren: () => import('./accreport/accreport.module').then( m => m.AccreportPageModule)
  },
  {
    path: 'acountentry',
    loadChildren: () => import('./acountentry/acountentry.module').then( m => m.AcountentryPageModule)
  },
  {
    path: 'addcats',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./addcats/addcats.module').then( m => m.AddcatsPageModule)
  },
  {
    path: 'staffs',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./staffs/staffs.module').then( m => m.StaffsPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'cpass',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./cpass/cpass.module').then( m => m.CpassPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'rreport',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./rreport/rreport.module').then( m => m.RreportPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'eod',
    loadChildren: () => import('./eod/eod.module').then( m => m.EodPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'credits',
    loadChildren: () => import('./credits/credits.module').then( m => m.CreditsPageModule)
  },
  {
    path: 'invcre',
    loadChildren: () => import('./invcre/invcre.module').then( m => m.InvcrePageModule)
  },
  {
    path: 'logs',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./logs/logs.module').then( m => m.LogsPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'invcpay',
    loadChildren: () => import('./invcpay/invcpay.module').then( m => m.InvcpayPageModule)
  },
  {
    path: 'invcv',
    loadChildren: () => import('./invcv/invcv.module').then( m => m.InvcvPageModule)
  },
  {
    path: 'stores',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./stores/stores.module').then( m => m.StoresPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'inventory',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./inventory/inventory.module').then( m => m.InventoryPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'storetran',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./storetran/storetran.module').then( m => m.StoretranPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'vstore/:id',
    loadChildren: () => import('./vstore/vstore.module').then( m => m.VstorePageModule)
  },
  {
    path: 'addstore',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./addstore/addstore.module').then( m => m.AddstorePageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'addspro',
    loadChildren: () => import('./addspro/addspro.module').then( m => m.AddsproPageModule)
  },
  {
    path: 'dsales',
    loadChildren: () => import('./dsales/dsales.module').then( m => m.DsalesPageModule)
  },
  {
    path: 'ssale/:id',
    loadChildren: () => import('./ssale/ssale.module').then( m => m.SsalePageModule)
  },
  {
    path: 'reports',
    canActivate: [AuthaccessGuard],
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule),
    data: { 
      expectedRole: '106'
    }
  },
  {
    path: 'sreports',
    loadChildren: () => import('./sreports/sreports.module').then( m => m.SreportsPageModule)
  },
  {
    path: 'cinvo',
    loadChildren: () => import('./cinvo/cinvo.module').then( m => m.CinvoPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'addpay',
    loadChildren: () => import('./addpay/addpay.module').then( m => m.AddpayPageModule)
  },
  {
    path: 'poorders',
    loadChildren: () => import('./poorders/poorders.module').then( m => m.PoordersPageModule)
  },
  {
    path: 'cpo',
    loadChildren: () => import('./cpo/cpo.module').then( m => m.CpoPageModule)
  },
  {
    path: 'po',
    loadChildren: () => import('./po/po.module').then( m => m.PoPageModule)
  },
  {
    path: 'poadd',
    loadChildren: () => import('./poadd/poadd.module').then( m => m.PoaddPageModule)
  },
  {
    path: 'pov',
    loadChildren: () => import('./pov/pov.module').then( m => m.PovPageModule)
  },
  {
    path: 'popay',
    loadChildren: () => import('./popay/popay.module').then( m => m.PopayPageModule)
  },
  {
    path: 'porep',
    loadChildren: () => import('./porep/porep.module').then( m => m.PorepPageModule)
  },
  {
    path: 'epo',
    loadChildren: () => import('./epo/epo.module').then( m => m.EpoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
