import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';

import { AuthService } from './services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
    constructor(private auth: AuthService,private router: Router){

    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
      //handle your auth error or rethrow
      if (err.status === 401 || err.status === 403) {
        console.log(err.status);
        //navigate /delete cookies or whatever
        this.auth.logouts();
        //location.reload(true);
       this.router.navigateByUrl('/login');
        // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
        //return of(err.message); // or EMPTY may be appropriate here
    }
      return throwError(err);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.auth.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    //const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn) {
        req = req.clone({
            setHeaders: {
                Authorization: `${currentUser.token}`
            }
        });
    }else{
      //console.log(currentUser.uid);
    }

    return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
  }
}