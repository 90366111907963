import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, retry} from 'rxjs/operators';
import {User} from '../model/user';
import { environment } from 'src/environments/environment';

//let apiUrl ='https://hexagonapp.com.ng/api';
let apiUrl = environment.uRl;
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
}
public get currentUserValue(): User {
  return this.currentUserSubject.value;
}
login(user: string, pass: string) {
  let params = 'user='+user+'&pass='+pass;
  let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  return this.http.post<any>(apiUrl+'/logon', params,{headers: headers})
      .pipe(map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if(user && user.key){
            //sessionStorage.setItem('currentUser', JSON.stringify(user.user));
            localStorage.setItem('currentUser', JSON.stringify(user.user));
          this.currentUserSubject.next(user);
          return user
          }
          this.currentUserSubject.next(null);
          return false;
      }));
}
logO(uid): Observable<any>{
  let params = 'uid='+uid;
  let headers = new HttpHeaders();
  headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
return this.http.post(apiUrl+'/logout', params,{headers: headers} ).pipe(retry(5))
}
logout(id) {
  // remove user from local storage to log user out
this.logO(id).subscribe((data)=>{
//sessionStorage.removeItem('currentUser');
localStorage.removeItem('currentUser');
});
localStorage.removeItem('currentUser');
this.currentUserSubject.next(null);
}
logouts(){
  localStorage.removeItem('currentUser');
this.currentUserSubject.next(null);
}


}
